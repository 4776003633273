import { wrapRootElement as wrap } from "./src/components/Code/wrap-root-element"
import Prism from "prism-react-renderer/prism"
;(typeof global !== "undefined" ? global : window).Prism = Prism

export const wrapRootElement = wrap

// Using line numbering for code blocks
require("prismjs/plugins/line-numbers/prism-line-numbers")

// Using shell prompt in code blocks
require("prismjs/plugins/command-line/prism-command-line.css")

// You can import missing languages supported by prismjs – https://prismjs.com/
require("prismjs/components/prism-bash")
require("prismjs/components/prism-css")
require("prismjs/components/prism-diff")
require("prismjs/components/prism-docker")
require("prismjs/components/prism-gherkin")
require("prismjs/components/prism-git")
require("prismjs/components/prism-graphql")
require("prismjs/components/prism-http")
require("prismjs/components/prism-json")
require("prismjs/components/prism-markdown")
require("prismjs/components/prism-markup")
require("prismjs/components/prism-php")
require("prismjs/components/prism-powershell")
require("prismjs/components/prism-jsx")
require("prismjs/components/prism-sass")
require("prismjs/components/prism-scss")
require("prismjs/components/prism-sql")
require("prismjs/components/prism-yaml")
