import React, { 
  createContext, 
  useState,
  useMemo,
} from 'react'

export const SearchContext = createContext({
  isSearch: false,
  /**
   * @param {boolean} isSearch 
   */
  setIsSearch: (isSearch) => { /**/ },
})

const SearchContextProvider = ({ children }) => {
  const [isSearch, setIsSearch] = useState(false)

  const value = useMemo(() => ({
    isSearch,
    setIsSearch,
  }), [
    isSearch,
    setIsSearch,
  ])

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContextProvider
